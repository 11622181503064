import { Box, Text } from "@chakra-ui/react";
import React from "react";

type CategoryTagType = {
  category: string;
};

const CategoryTag = ({ category }: CategoryTagType) => {
  return (
    <Box
      display="flex"
      borderRadius="48px"
      px="24px"
      py="12px"
      color="#fff"
      bg="#D28C49"
      fontSize="md"
      fontWeight="600"
      alignItems="center"
      justifyContent="center"
    //   _hover={{ background: "purple.700" }}
    >
      <Text fontSize="md" fontWeight={500} lineHeight="117%" textTransform="uppercase">{category}</Text>
    </Box>
  );
};

export default CategoryTag;
